<template>
  <div class="confirm-wrap">
    <div class="bread-wrap">
      <span class="bread-title">个人中心/企业证件认证</span>
    </div>
    <div class="content">
      <el-result
        icon="success"
        title="已提交审核"
        subTitle="请耐心等待审核结果"
      >
        <template slot="extra">
          <el-button
            @click="naviuser"
            class="submitconfirm"
            type="primary"
            size="medium"
            >确定</el-button
          >
        </template>
      </el-result>
    </div>
  </div>
</template>
<script>
import navigation from "../../../utils/navigation";
export default {
  data() {
    return {};
  },
  methods: {
    naviuser() {
      navigation.navigateTo({
        url: "personalcenter",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>